import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import Dialog from '@mui/material/Dialog';
import { columns } from './statusTableConfig';
import StatusView from './statusView/statusView';
import { isCardPaymentApplicable } from './utils/statusUtils';

const StatusTable = ({ statuses }) => {
  const [tableData, setTableData] = useState([]);
  const [selectedStatusID, setSelectedStatusID] = useState('');
  const [dialogState, setDialogState] = React.useState(false);

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  function formatDateTime(date) {
    var d = new Date(date),
      year = d.getFullYear(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      hours = '' + d.getHours(),
      minutes = '' + d.getMinutes();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;

    return [year, month, day].join('-') + ' ' + [hours, minutes].join(':');
  }

  useEffect(() => {
    let temp = [];
    if (statuses) {
      statuses.forEach((status) => {
        temp.push({
          ReqID: status.reqData._id,
          date: formatDate(status.reqData.rfpdate),
          chosendate:
            status.reqData.chosendate === ''
              ? 'Unknown'
              : status.reqData.chosendate === 'asap'
                ? 'Azonnal'
                : status.reqData.chosendate === '2weeks'
                  ? '2 Héten belül'
                  : status.reqData.chosendate === '2months'
                    ? '2 Hónapon belül'
                    : status.reqData.chosendate === '6months'
                      ? 'Később'
                      : '',
          name: status.reqData.companyctcname ? status.reqData.companyctcname : 'Unknown',
          email: status.reqData.email ? status.reqData.email : 'Not provided',
          phone: status.reqData.companyPhone ? status.reqData.companyPhone : 'Not provided',
          cafstatus: status.reqData.reqstatus ? status.reqData.reqstatus.cafstatus : 'Unknown',
          aspstatus: status.reqData.reqstatus ? status.reqData.reqstatus.aspstatus : 'Unknown',
          herstatus: status.reqData.reqstatus ? status.reqData.reqstatus.herstatus : 'Unknown',
          BupaID: status.reqData.BupaID ? 'Igen' : 'Nem',
          responsibleagent: status.reqData.responsibleagent ? status.reqData.responsibleagent : 'Nincs kiválasztva',
          chosenprovider: status.reqData.chosenprovider ? status.reqData.chosenprovider : 'Nincs kiválasztva',
          interactduedate: (() => {
            if (!status.reqData?.interacttodo || status.reqData.interacttodo.length <= 1) {
              return '';
            }

            const validDates = status.reqData.interacttodo
              .slice(1)
              .filter(item => item && item.completed === false && item.date)
              .map(item => new Date(item.date))
              .filter(date => !isNaN(date));

            if (validDates.length === 0) {
              return '';
            }

            const earliestDate = new Date(Math.min(...validDates));

            return formatDate(earliestDate);
          })(),
          leadtype:
            !status.reqData.leadtype
              ? 'nem besorolt'
              : status.reqData.leadtype.toLowerCase() === 'vaf'
                ? 'VAF lead'
                : status.reqData.leadtype.toLowerCase() === 'reg'
                  ? 'REG lead'
                  : 'ASP lead',
          custservmessage: status.reqData.custservmessage ? 'Van üzenet' : 'Nincs üzenet',
          messageAboutSP: status.reqData.messageAboutSP ? 'Van üzenet' : 'Nincs üzenet',
          isCardPaymentCompleted: !isCardPaymentApplicable(status.reqData.leadtype) ? 'Not used' : status.reqData.isCardPaymentCompleted == null ? 'No data' : status.reqData.isCardPaymentCompleted ? 'Paid' : 'Not paid'
        });
      });
    }
    setTableData(temp);
  }, [statuses]);

  const handleClose = () => {
    setDialogState(false);
  };

  const options = {
    filterType: 'checkbox',
    rowHover: true,
    selectableRows: false,
    onRowClick: (rowData, rowMeta) => {
      //if columns get rearranged change the index here -- unfortunately rowData is flattened
      setSelectedStatusID(rowData[0]);
      setDialogState(true);
    },
  };

  return (
    <div>
      <MUIDataTable data={tableData} columns={columns} options={options} />
      <Dialog
        fullScreen
        fullWidth={true}
        maxWidth={'xl'}
        open={dialogState}
        onClose={handleClose}
        setDialogState={setDialogState}
      >
        <StatusView statusID={selectedStatusID} />
      </Dialog>
    </div>
  );
};

export default StatusTable;
