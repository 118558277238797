import PropTypes from 'prop-types';
import { useEffect, useContext, useState, useCallback } from 'react';
import { /* Link as RouterLink, */ useLocation } from 'react-router';
// material
import { styled } from '@mui/material/styles';
import { Box, /*  Link, */ Drawer /* Typography, Avatar */ } from '@mui/material';
// mock
/* import account from '../../_mock/account'; */
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import navConfig from './NavConfig';
import Dropdown from 'src/components/Dropdown';
//context
import { EnvContext } from 'src/context/EnvContext';
import { fetchData } from '../../fetch';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 170;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

/* const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
})); */

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

const urls = {
  'PROD-HU': process.env.REACT_APP_BACKEND_API_PROD_HU || 'https://api-hu.bookkeepie.com',
  'DEV-HU': process.env.REACT_APP_BACKEND_API_DEV_HU || 'https://api-dev-hu.bookkeepie.com',
  'PROD-RO': process.env.REACT_APP_BACKEND_API_PROD_RO || 'https://api-ro.bookkeepie.com',
  'DEV-RO': process.env.REACT_APP_BACKEND_API_DEV_RO || 'https://api-dev-ro.bookkeepie.com',
  'PROD-IE': process.env.REACT_APP_BACKEND_API_PROD_IE || 'https://api-ie.bookkeepie.com',
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  /*   const { setEnv } = useContext(EnvContext);*/
  const [env, setEnv] = useState('PROD-HU');
  const { setBaseUrl } = useContext(EnvContext);

  const isDesktop = useResponsive('up', 'lg');

  const getAuthenticated = useCallback(async (database) => {
    //console.log(database, 'database', localStorage.getItem('cognitoID'));
    await fetchData(
      'https://v7bwkuf6vl.execute-api.eu-west-1.amazonaws.com/prod/admin-auth',
      'POST',
      localStorage.getItem('cognitoID'),
      { database: database }
    )
      .then(async (response) => {
        let resp = await response.json();
        localStorage.setItem('JWT', resp.jwt);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    getAuthenticated(env.toLowerCase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const dropdownChange = (selectedValue) => {
    console.log(selectedValue, 'selectedValue');
    setEnv(selectedValue);
    console.log(env, 'ENV');
    setBaseUrl(urls[selectedValue]);
    getAuthenticated(selectedValue.toLowerCase());
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box
        sx={{
          px: 2.5,
          py: 3,
          display: 'flex',
          alignItems: 'center',
          '.css-z4zust-MuiInputBase-root-MuiInput-root-MuiSelect-root': { width: '100px' },
        }}
      >
        <Logo />
        <Dropdown
          label="Database"
          list={[
            { label: 'PROD-HU', id: 'PROD-HU' },
            { label: 'DEV-HU', id: 'DEV-HU' },
            { label: 'PROD-RO', id: 'PROD-RO' },
            { label: 'DEV-RO', id: 'DEV-RO' },
            { label: 'PROD-IE', id: 'PROD-IE' },
          ]}
          selValue={env}
          onChange={(value) => dropdownChange(value)}
        />
      </Box>

      <NavSection navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
