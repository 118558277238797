import React, { useEffect, useState, useContext } from 'react';
import TextInput from 'src/components/TextInput';
import { contactInputs, secondaryAddressInputs, secContactInputs, secAddressInputs } from '../profileHelper';
import { ProviderContext } from '../ProviderProfile';
import ImageUploader from './components/ImageUploader';
import { MenuItem, InputLabel, Select } from '@mui/material';

const Profile = () => {
  const [data, setData] = useState('');
  const { providerData, updateData } = useContext(ProviderContext);

  useEffect(() => {
    if (providerData) {
      setData({
        provider_info: providerData.provider_info,
        provider_contact: providerData.provider_contact,
        provider_languages: providerData.provider_languages,
      });
    }
  }, [providerData]);
  const handleChange = async (id, value) => {
    await updateData('provider', { [id]: value });
  };
  const selectHandler = async (name, value) => {
    await updateData('provider', { [name]: value });
  };

  return (
    <div className="TabContent">
      {data ? (
        <div className="TabContentFlexRow">
          <div className="Padding12">
            {contactInputs.map((item, i) => {
              return (
                <>
                  {i === 0 && <b>Elsődleges cim adatok:</b>}
                  <div className="TextInput" key={i}>
                    <TextInput
                      label={item.label}
                      id={`${item.modelKey}.${item.id}`}
                      valueProp={data[item.modelKey][item.id]}
                      onChange={handleChange}
                    />
                  </div>
                </>
              );
            })}
            {secondaryAddressInputs.map((item, i) => {
              return (
                <>
                  {i === 0 && <b>Székhely cim adatok:</b>}
                  <div className="TextInput" key={i}>
                    <TextInput
                      label={item.label}
                      id={`${item.modelKey}.${item.id}`}
                      valueProp={data[item.modelKey][item.id]}
                      onChange={handleChange}
                    />
                  </div>
                </>
              );
            })}
          </div>
          <div className="Padding12">
            {secContactInputs.map((item, i) => {
              return (
                <>
                  {i === 0 && <b>Második cég cim adatok:</b>}
                  <div className="TextInput" key={i}>
                    <TextInput
                      label={item.label}
                      id={`${item.modelKey}.${item.id}`}
                      valueProp={data[item.modelKey][item.id]}
                      onChange={handleChange}
                    />
                  </div>
                </>
              );
            })}
            {secAddressInputs.map((item, i) => {
              return (
                <>
                  {i === 0 && <b>Második cég székhely cim adatok:</b>}
                  <div className="TextInput" key={i}>
                    <TextInput
                      label={item.label}
                      id={`${item.modelKey}.${item.id}`}
                      valueProp={data[item.modelKey][item.id]}
                      onChange={handleChange}
                    />
                  </div>
                </>
              );
            })}
          </div>
          <div className="Padding12">
            <div className="TextInput">
              <TextInput
                label="Bemutatkozás"
                id="provider_info.description"
                valueProp={data.provider_info.description}
                onChange={handleChange}
                multiline
              />
            </div>
            <InputLabel id="highpriority-label">Kiemelten ügyféllel ellátandó könyvelő</InputLabel>
            <Select
              id="provider_info.highpriority"
              name="provider_info.highpriority"
              onChange={(e) => selectHandler(e.target.name, e.target.value)}
              value={data.provider_info.highpriority}
              variant="standard"
              labelId="highpriority-label"
              style={{ width: '350px', marginBottom: '16px' }}
            >
              <MenuItem value={'highpriority'}>Nagyon kiemelt</MenuItem>
              <MenuItem value={'lowpriority'}>Kicsit kiemelt</MenuItem>
              <MenuItem value={'nopriority'}>Nem kiemelt</MenuItem>
              <MenuItem value={'avoid'}>Kerülendő</MenuItem>
            </Select>

            <InputLabel id="asponboard-label">ASP Onboard</InputLabel>
            <Select
              id="provider_info.asponboard"
              name="provider_info.asponboard"
              onChange={(e) => selectHandler(e.target.name, e.target.value)}
              value={data.provider_info.asponboard}
              variant="standard"
              labelId="asponboard-label"
              style={{ width: '350px', marginBottom: '16px' }}
            >
              <MenuItem value={true}>Igen</MenuItem>
              <MenuItem value={false}>Nem</MenuItem>
            </Select>

            <InputLabel id="gotcustomer-label">Hoztunk már ASP árbevételt</InputLabel>
            <Select
              id="provider_info.gotcustomer"
              name="provider_info.gotcustomer"
              onChange={(e) => selectHandler(e.target.name, e.target.value)}
              value={data.provider_info.gotcustomer}
              variant="standard"
              labelId="gotcustomer-label"
              style={{ width: '350px', marginBottom: '16px' }}
            >
              <MenuItem value={true}>Igen</MenuItem>
              <MenuItem value={false}>Nem</MenuItem>
            </Select>

            <InputLabel id="responsetime-label">Milyen gyorsan válaszol az SP</InputLabel>
            <Select
              id="provider_info.responsetime"
              name="provider_info.responsetime"
              onChange={(e) => selectHandler(e.target.name, e.target.value)}
              value={data.provider_info.responsetime}
              variant="standard"
              labelId="responsetime-label"
              style={{ width: '350px', marginBottom: '16px' }}
            >
              <MenuItem value={'veryfast'}>Nagyon gyors (2 órán belül)</MenuItem>
              <MenuItem value={'fast'}>Gyors (2-4 órán belül)</MenuItem>
              <MenuItem value={'decent'}>Rendben van (1 napon belül)</MenuItem>
              <MenuItem value={'nodata'}>Nincs adat</MenuItem>
              <MenuItem value={'slow'}>Lassú, körülményes</MenuItem>
            </Select>
            <div className="TextInput">
              <TextInput
                label="Időpont foglalás"
                id="provider_info.bookinglink"
                valueProp={data.provider_info.bookinglink}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="Padding12">
            <div className="TextInput">
              <TextInput
                label="Soft statok"
                id="provider_info.softstats"
                valueProp={data.provider_info.softstats}
                onChange={handleChange}
              />
            </div>
            <div className="TextInput">
              <TextInput
                label="Extra szolgáltatások"
                id="provider_info.extraservices"
                valueProp={data.provider_info.extraservices}
                onChange={handleChange}
              />
            </div>
            <div className="Width350">
              <ImageUploader />
            </div>
          </div>
        </div>
      ) : (
        <>Loading</>
      )}
    </div>
  );
};

export default Profile;
